<template>
  <div>
    <!-- Top-level row -->
    <b-form-row>
      <!-- Plots -->
      <b-col cols="12" sm="6" lg="3">
        <b-row>
          <b-col cols="6" style="padding-right: 2px">
            <InfoBox
              footer="Number of Tools Deployed"
              :description="`Total number of HD tools deployed in ${country}`"
            >
              <h2>{{ nTools }}</h2>
            </InfoBox>
          </b-col>
          <b-col cols="6" style="padding-left: 2px">
            <InfoBox
              footer="Total Active HD Customers"
              description="Total number of unique active customers. Only active HoReCa MCC customers who are also HD customers are taken into account."
            >
              <h2>{{ nActive }}</h2>
            </InfoBox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="box-container flex">
              <BarChart
                title="Number of HD customers"
                :series="cumulativeDigitalCustomers"
                :categories="digitalCustomerCategories"
                :height="124"
                :shortAxisLabels="true"
                :locale="locale"
                description="Development of cumulative customer numbers in the last 12 months. Only active HoReCa MCC customers who are also HD customers are taken into account."
                subheader="Until end of last month"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- Plots -->
      <b-col cols="12" sm="6" lg="2">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="Total customers per tool"
            :series="totalToolCustomers"
            :categories="totalToolCategories"
            :distributed="false"
            :locale="locale"
            description="Total number of (active) customers per tool. Only active HoReCa MCC customers who are also HD customers are taken into account."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="2">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="New customers per tool"
            :series="newToolCustomers"
            :categories="newToolCategories"
            :distributed="false"
            :locale="locale"
            description="Gross number of newly registered customers per tool in the last 12 months. Only active HoReCa MCC customers who are also HD customers are taken into account."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="2">
        <div class="box-container fixed">
          <BarChart
            :title="
              `Total tool revenue (DISH) [${this.currencyMapper[this.locale]}]`
            "
            :series="totalToolRevenue"
            :categories="toolRevenueCategories"
            :isCurrency="true"
            :locale="locale"
            description="Net HD tool revenue in the last 12 months (all reseller). Note: Reserv. = Professional Reservation; Order = Professional Order"
            subheader="Until end of last month"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <HorizontalBarChart
            title="Churn comparison [%]"
            :series="churnValue"
            :categories="churnCategories"
            :isPercentage="true"
            :locale="locale"
            :enableDataLabels="true"
            description="Percentage customer outflow rates for digital vs. non-digital customers - customers that have not made a purchase in the last 12 months are considered as churned. Limited to HoReCa customers created more than 12 months ago."
            subheader="Until end of last month"
          />
        </div>
      </b-col>

      <!-- Second Row -->
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <div>
            <b-form-row>
              <b-col>
                <div style="min-height: 100px">
                  <AreaChart
                    :title="
                      `Average basket value [${
                        this.currencyMapper[this.locale]
                      }]`
                    "
                    :series="averageSalesValue"
                    :categories="averageSalesCategories"
                    :isCurrency="true"
                    :locale="locale"
                    :height="100"
                    :slimline="true"
                    description="Average monthly basket value for digital/non-digital customers for last 12 months. Limited to active HD and HoReCa customers created more than 12 months ago."
                    subheader="Until end of last month, monthly average"
                  />
                </div>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <div style="min-height: 100px; margin-top: -15px">
                  <HorizontalBarChart
                    title="Purchasing frequency"
                    :series="purchasingFrequency"
                    :categories="purchasingCategories"
                    :height="85"
                    :enableDataLabels="true"
                    description="Average number of visits for digital/non-digital customers in last 12 months. Limited to active HD and HoReCa customers created more than 12 months ago."
                    subheader="Until end of last month, yearly average"
                  />
                </div>
              </b-col>
            </b-form-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <AreaChart
            :title="
              `Cumulative sales value [${this.currencyMapper[this.locale]}]`
            "
            :series="cumulativeValue"
            :categories="cumulativeValueCategories"
            :isCurrency="true"
            :locale="locale"
            description="Average basket value x average number of visits for digital/non-digital customers. Limited to active HD and HoReCa customers created more than 12 months ago."
            subheader="Until end of last month, cumulative monthly average"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <BarChart
            title="Digital sales uplift [%]"
            :series="salesUplift"
            :categories="salesUpliftCategories"
            :isPercentage="true"
            :shortAxisLabels="true"
            :locale="locale"
            description="Average monthly sales uplift per customer: Incremental MCC sales value for new HD customers (being in 12 months period after moment of 'digital conversion') vs. non-digital customers for the last 12 months. Limited to active HD and HoReCa customers being mcc customers for more than two years."
            subheader="Until end of last month, monthly average"
          />
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container fixed">
          <Gauge
            title="Own brand share [%]"
            :series="obProportion"
            :categories="obCategories"
            description="Percentage of MCC revenue generated via purchases of METRO own brands for digital and non-digital customers, based on last 12 months. Limited to active HD and HoReCa customers created more than 12 months ago."
            subheader="Until end of last month"
          />
        </div>
      </b-col>
    </b-form-row>
    <!-- Financial Year View -->
    <b-form-row>
      <b-col>
        <div class="separator-box">
          <div class="separator-main">
            Actual vs. Target per Product | Financial Year Perspective
          </div>
        </div>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Starter"
              :series="dishStartAcqs"
              :categories="dishStartAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for MCC are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishStartAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishStartAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Professional Reservation"
              :series="dishResAcqs"
              :categories="dishResAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for MCC are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishResAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishResAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Professional Order"
              :series="dishOrdAcqs"
              :categories="dishOrdAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for MCC are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>

          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishOrdAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishOrdAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col cols="12" sm="6" lg="3">
        <div class="box-container flex">
          <div style="min-height: 150px">
            <LineChart
              title="DISH Premium"
              :series="dishPremAcqs"
              :categories="dishPremAcqsCategories"
              description="Actual (gross) and planned acquisition figures for tool. Only customers acquired for MCC are taken into account."
              subheader="# of new customers (cum.) | Current FY"
              :locale="locale"
            />
          </div>
          <div class="target-container">
            <b-row>
              <b-col cols="6" style="padding-right: 2px">
                <InfoBox footer="Actual vs Target YTD">
                  <h2>{{ dishPremAcqsYTD }}</h2>
                </InfoBox>
              </b-col>
              <b-col cols="6" style="padding-left: 2px">
                <InfoBox footer="Actual vs Target FY">
                  <h2>{{ dishPremAcqsFY }}</h2>
                </InfoBox>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import InfoBox from "@/components/InfoBox.vue";
import Gauge from "@/components/Plots/Gauge.vue";
import BarChart from "@/components/Plots/BarChart.vue";
import HorizontalBarChart from "@/components/Plots/HorizontalBarChart.vue";
import AreaChart from "@/components/Plots/AreaChart.vue";
import LineChart from "@/components/Plots/LineChart.vue";
import formatValue from "@/components/Plots/formatter";

export default {
  name: "template-country-view",
  props: { country: { type: String }, locale: { type: String } },
  components: {
    InfoBox,
    Gauge,
    BarChart,
    HorizontalBarChart,
    AreaChart,
    LineChart
  },
  data() {
    return {
      //
      currencyMapper: {
        at: "€",
        be: "€",
        hr: "kn",
        cz: "Kč",
        fr: "€",
        nl: "€",
        de: "€",
        hu: "Ft",
        it: "€",
        pl: "zł",
        pt: "€",
        ro: "L",
        sk: "€",
        es: "€",
        tr: "₺",
        ua: "₴"
      },
      nActive: "-",
      nTools: "-",
      //
      digitalCustomerCategories: [],
      cumulativeDigitalCustomers: [],
      //
      totalToolCategories: [],
      totalToolCustomers: [],
      //
      newToolCategories: [],
      newToolCustomers: [],
      //
      toolRevenueCategories: [],
      totalToolRevenue: [],
      //
      averageSalesCategories: [],
      averageSalesValue: [],
      //
      purchasingFrequency: [],
      purchasingCategories: [],
      //
      cumulativeValue: [],
      cumulativeValueCategories: [],
      //
      salesUpliftCategories: [],
      salesUplift: [],
      //
      churnCategories: [],
      churnValue: [],
      //
      obProportion: [],
      obCategories: [],
      //
      dishStartAcqs: [],
      dishStartAcqsCategories: [],
      dishStartAcqsYTD: "-",
      dishStartAcqsFY: "-",
      //
      dishResAcqs: [],
      dishResAcqsCategories: [],
      dishResAcqsYTD: "-",
      dishResAcqsFY: "-",
      //
      dishOrdAcqs: [],
      dishOrdAcqsCategories: [],
      dishOrdAcqsYTD: "-",
      dishOrdAcqsFY: "-",
      //
      dishPremAcqs: [],
      dishPremAcqsCategories: [],
      dishPremAcqsYTD: "-",
      dishPremAcqsFY: "-",
      //

      timer: ""
    };
  },
  methods: {
    fetchData() {
      this.fetchDigitalCustomers();
      this.fetchTotalToolCustomers();
      this.fetchDigitalActive();
      this.fetchNewToolCustomers();
      this.fetchDeployedTools();
      this.fetchToolRevenue();
      this.fetchSalesValue();
      this.fetchCumulativeValue();
      this.fetchSalesUplift();
      this.fetchChurn();
      this.fetchObProportion();
      this.fetchAcquisition();
    },
    fetchDigitalCustomers() {
      this.$http
        .get(`/api/v1/customers/digital`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.digitalCustomerCategories = response.data.categories;
          this.cumulativeDigitalCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchTotalToolCustomers() {
      this.$http
        .get(`/api/v1/customers/total`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.totalToolCategories = response.data.categories;
          this.totalToolCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchDigitalActive() {
      this.$http
        .get(`/api/v1/customers/activedigital`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.nActive = response.data.value;
        })
        .catch(() => {});
    },
    fetchNewToolCustomers() {
      this.$http
        .get(`/api/v1/customers/new`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.newToolCategories = response.data.categories;
          this.newToolCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchToolRevenue() {
      this.$http
        .get(`/api/v1/tools/revenue`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.toolRevenueCategories = response.data.categories;
          this.totalToolRevenue = response.data.data;
        })
        .catch(() => {});
    },
    fetchDeployedTools() {
      this.$http
        .get(`/api/v1/tools/deployed`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.nTools = response.data.value;
        })
        .catch(() => {});
    },
    fetchSalesValue() {
      this.$http
        .get(`/api/v1/transactions/sales`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.averageSalesValue = response.data.basket.data;
          this.averageSalesCategories = response.data.basket.categories;

          this.purchasingFrequency = response.data.visits.data;
          this.purchasingCategories = response.data.visits.categories;
        })
        .catch(() => {});
    },
    fetchCumulativeValue() {
      this.$http
        .get(`/api/v1/transactions/cumulativevalue`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.cumulativeValue = response.data.data;
          this.cumulativeValueCategories = response.data.categories;
        })
        .catch(() => {});
    },
    fetchSalesUplift() {
      this.$http
        .get(`/api/v1/transactions/uplift`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.salesUplift = response.data.percentage;
          this.salesUpliftCategories = response.data.categories;
        })
        .catch(() => {});
    },
    fetchChurn() {
      this.$http
        .get(`/api/v1/customers/churn`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          this.churnCategories = response.data.categories;
          this.churnValue = response.data.data;
        })
        .catch(() => {});
    },
    fetchObProportion() {
      this.$http
        .get(`/api/v1/transactions/ownbrand`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          // JSON responses are automatically parsed.
          this.obCategories = response.data.categories;
          this.obProportion = response.data.data;
        })
        .catch(() => {});
    },
    fetchAcquisition() {
      this.$http
        .get(`/api/v1/tools/acquisitions`, {
          params: {
            country: this.country
          }
        })
        .then(response => {
          //
          this.dishStartAcqs = response.data.dishStart.plot.data;
          this.dishStartAcqsCategories =
            response.data.dishStart.plot.categories;
          this.dishStartAcqsYTD = formatValue(
            response.data.dishStart.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishStartAcqsFY = formatValue(
            response.data.dishStart.kpi.fy,
            this.locale,
            false,
            true
          );
          //
          this.dishResAcqs = response.data.dishRes.plot.data;
          this.dishResAcqsCategories = response.data.dishRes.plot.categories;
          this.dishResAcqsYTD = formatValue(
            response.data.dishRes.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishResAcqsFY = formatValue(
            response.data.dishRes.kpi.fy,
            this.locale,
            false,
            true
          );
          //
          this.dishOrdAcqs = response.data.dishOrd.plot.data;
          this.dishOrdAcqsCategories = response.data.dishOrd.plot.categories;
          this.dishOrdAcqsYTD = formatValue(
            response.data.dishOrd.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishOrdAcqsFY = formatValue(
            response.data.dishOrd.kpi.fy,
            this.locale,
            false,
            true
          );
          // !!!!!!!!!!!!! PREM SECTION MUST BE UPDATED WHEN DATA STRUCTURE IS KNOWN
          this.dishPremAcqs = response.data.dishPrem.plot.data;
          this.dishPremAcqsCategories = response.data.dishPrem.plot.categories;
          this.dishPremAcqsYTD = formatValue(
            response.data.dishPrem.kpi.ytd,
            this.locale,
            false,
            true
          );
          this.dishPremAcqsFY = formatValue(
            response.data.dishPrem.kpi.fy,
            this.locale,
            false,
            true
          );
          // !!!!!!!!!!!!!
        })
        .catch(() => {});
    },
    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName:
                "EN:XX:VALUE CREATION DASHBOARD: " +
                document.URL +
                " -> " +
                this.locale,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId
            }
          }
        })
      );
    }
  },

  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 7200000);
    this.submitPageChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
h2 {
  font-size: 36px;
  margin-bottom: 5px;
}

.separator-box {
  padding: 10px;
}
.separator-main {
  font-size: 25px;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 5px;
  text-shadow: 1px 1px 1px #bbb;
  padding: 5px;
  border-bottom: 1px double black;
}

.target-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -10px;
}
</style>
