import Vue from "vue";

// plugins
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/main.css";

import App from "./App.vue";
import router from "./router";
import authenticator from "./plugins/authenticator";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(authenticator);

Vue.$keycloak
  .init({
    onLoad: "login-required",
    checkLoginIframe: false,
    pkceMethod: "S256"
  })
  .then(() => {
    new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  });
