<template>
  <div>
    <b-row>
      <b-col>
        <div class="background fullscreen">
          <div class="overlay"></div>

          <div class="container">
            <div class="title">Digital value creation dashboard</div>
            <div class="disclaimer">
              Disclaimer: Only country pages contain up to date and checked
              values
            </div>
            <hr class="my-4" />
            <div class="blurb">
              The digital value creation dashboard will analyze the digital
              impact on the METRO core business and should enable transparent
              management of digital value creation activities within the country
              organizations
            </div>
            <hr class="my-4" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-form-row>
      <b-col>
        <div class="link-container">
          <b-row>
            <b-col xl="4" md="6" sm="12">
              <div class="link-box">
                <router-link to="/value">
                  <div class="icon-container">
                    <b-icon-cart3 />
                  </div>
                  <h4>Value Creation</h4>
                  <hr />
                  HD Value Creation
                </router-link>
              </div>
            </b-col>
            <b-col xl="4" md="6" sm="12">
              <div class="link-box">
                <router-link to="/overview">
                  <div class="icon-container">
                    <b-icon-globe2 />
                  </div>
                  <h4>Overview</h4>
                  <hr />
                  Global Overview KPIs
                </router-link>
              </div>
            </b-col>
            <b-col xl="4" md="6" sm="12">
              <div class="link-box">
                <router-link to="/country">
                  <div class="icon-container">
                    <b-icon-geo-alt-fill />
                  </div>
                  <h4>Country</h4>
                  <hr />
                  Country Specific KPIs
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  name: "splash-view",

  methods: {
    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName: "EN:XX:VALUE CREATION DASHBOARD: " + document.URL,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId
            }
          }
        })
      );
    }
  },
  created() {
    this.submitPageChange();
  }
};
</script>

<style scoped>
.fullscreen {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  min-height: calc(100vh - 300px);
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.background {
  background-image: url("../assets/splash-vertices.jpg");
  /* Full height */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.overlay {
  background-color: #0040852e;
  min-height: calc(100vh - 300px);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.container {
  z-index: 1;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}
.title {
  font-size: 4.6rem;
  font-weight: 100;
}
@media (max-width: 992px) {
  .title {
    font-size: 3.68rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: calc(2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(
      1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)))
    );
  }
}
.disclaimer {
  color: rgb(249, 174, 0);
  font-weight: 600;
  background-color: #191919d1;
  text-align: center;
  padding: 10px;
}
.blurb {
  padding: 0 50px;
}

.link-container {
  margin: auto;
  width: 90vw;
  padding: 15px 40px;
}
.link-box {
  min-height: 50px;
  max-width: 300px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  background-color: #fbfcfd;
  font-size: 15px;
  border-radius: 3px;
}
.link-box:hover {
  box-shadow: rgba(126, 126, 126, 0.8) 0px 5px 58px -16px,
    rgba(128, 128, 128, 0.72) 0px 20px 22px -10px;
  transform: scale(1.03) translateZ(0px) translate3d(0, 0, 0);
  transition-duration: 300ms;
  transition-property: transform, box-shadow, background-color, border;
  transition-timing-function: ease-out;
  border: solid 1px #002146;
}
hr {
  padding: 0;
  margin: 4px;
  background: white;
}
.icon-container {
  font-size: 50px;
  color: #002146;
  margin: 3px;
}
a {
  color: black;
  text-decoration: transparent;
}
</style>
