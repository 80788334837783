<template>
  <div v-if="isLoading" class="plot-container text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <div class="h2 mb-0">
      <div class="plot-title">
        {{ title }}
        <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
        <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
          <div class="tooltip-internal">
            {{ description }}
          </div>
        </b-tooltip>
      </div>
      <div class="plot-subheader">{{ subheader }}</div>
    </div>
    <apexchart
      type="area"
      :height="plotHeight"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatter";

export default {
  name: "ApexLine",
  props: {
    title: String,
    series: Array,
    categories: Array,
    height: { type: Number, default: 215 },
    slimline: { type: Boolean, default: false },
    description: { type: String, default: "" },
    subheader: { type: String, default: "" },
    isCurrency: { type: Boolean, default: false },
    locale: { type: String }
  },
  computed: {
    plotHeight: function() {
      if (this.subheader === "") {
        return this.height + 12;
      } else {
        return this.height;
      }
    },
    isLoading: function() {
      return this.series.length === 0;
    },
    hasDescription: function() {
      return this.description != "";
    },
    chartOptions: function() {
      return {
        colors: ["rgb(249, 174, 0)", "rgb(0, 59, 126)", "rgb(112, 174, 223)"],
        title: {
          text: ""
        },
        chart: {
          fontFamily: "Calibri",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            speed: 500,
            animateGradually: {
              delay: 50
            }
          },
          sparkline: {
            enabled: this.sparkline
          }
        },
        dropShadow: {
          enabled: true,
          opacity: 0.3,
          blur: 5,
          left: -7,
          top: 22
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.categories,
          type: "datetime",
          labels: {
            style: {
              colors: "black"
            },
            offsetY: -3
          },
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: true,
            color: "black"
          }
        },
        yaxis: {
          forceNiceScale: true,
          axisBorder: {
            show: true,
            color: "black",
            offsetX: -3
          },
          labels: {
            offsetX: -10,
            style: {
              colors: "black",
              fontWeight: 900
            },
            formatter: value => {
              return formatValue(value, this.locale, this.isCurrency);
            }
          }
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        tooltip: {
          x: {
            format: "MMM yyyy"
          }
        },
        legend: {
          show: !this.slimline,
          offsetY: 3
        },
        grid: {
          show: true,
          strokeDashArray: 0,
          padding: {
            left: 0,
            top: -20,
            bottom: -5
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    }
  }
};
</script>
