<template>
  <div>
    <b-container class="bv-example-row" fluid>
      <!-- Stack the columns on mobile by making one full-width and the other half-width -->
      <b-form-row>
        <!-- Top Row -->
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <BarChart
              title="Number of digital customers"
              :series="cumulativeDigitalCustomers"
              :categories="digitalCustomerCategories"
              :shortAxisLabels="true"
              :locale="locale"
              description="Cumulative number of digital customers registered with HD tools in the last 12 months"
              subheader="Up to 31/12/2020"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="2">
          <div class="box-container fixed">
            <HorizontalBarChart
              title="Total customers per tool"
              :series="totalToolCustomers"
              :categories="totalToolCategories"
              :distributed="false"
              :locale="locale"
              description="Total number of customers per tool"
              subheader="Up to 31/12/2020"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="2">
          <div class="box-container fixed">
            <HorizontalBarChart
              title="New customers per tool"
              :series="newToolCustomers"
              :categories="newToolCategories"
              :distributed="false"
              :locale="locale"
              description="Total number of digital customers registered with HD tools in the last 12 months"
              subheader="Up to 31/12/2020"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="2">
          <div class="box-container fixed">
            <BarChart
              title="Total tool revenue [€]"
              :series="totalToolRevenue"
              :categories="toolRevenueCategories"
              :isCurrency="true"
              :locale="locale"
              description="Net HD revenue"
              subheader="Up to 31/12/2020"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <HorizontalBarChart
              title="Churn comparison [%]"
              :series="churnValue"
              :categories="churnCategories"
              :isPercentage="true"
              :locale="locale"
              :enableDataLabels="true"
              description="Percentage customer outflow rates for digital vs. non-digital customers - customers that have not made a purchase in the last 12 months."
              subheader="Up to 31/12/2020, Monthly Average"
            />
          </div>
        </b-col>

        <!-- Second Row -->
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <div>
              <b-form-row>
                <b-col>
                  <div style="min-height: 100px">
                    <AreaChart
                      title="Average basket value [€]"
                      :series="averageSalesValue"
                      :categories="averageSalesCategories"
                      :isCurrency="true"
                      :locale="locale"
                      :height="100"
                      :slimline="true"
                      subheader="Up to 31/12/2020, Monthly Average"
                    />
                  </div>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <div style="min-height: 100px; margin-top: -15px">
                    <HorizontalBarChart
                      title="Purchasing frequency"
                      :series="purchasingFrequency"
                      :categories="purchasingCategories"
                      :height="85"
                      :enableDataLabels="true"
                      subheader="Up to 31/12/2020, Yearly Average"
                    />
                  </div>
                </b-col>
              </b-form-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <AreaChart
              title="Cumulative sales value [€]"
              :series="cumulativeValue"
              :categories="cumulativeValueCategories"
              :isCurrency="true"
              :locale="locale"
              description="Average basket value x Average visits for Digital/Non-Digital customers"
              subheader="Up to 31/12/2020, Monthly Average"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <BarChart
              title="Digital sales uplift [€]"
              :series="salesUplift"
              :categories="salesUpliftCategories"
              :isCurrency="true"
              :enableDataLabels="false"
              :shortAxisLabels="true"
              :locale="locale"
              description="Average monthly sales uplift per customer"
              subheader="Up to 31/12/2020, Monthly Average"
            />
          </div>
        </b-col>
        <b-col cols="12" sm="6" lg="3">
          <div class="box-container fixed">
            <Gauge
              title="Own brand share [%]"
              :series="obProportion"
              :categories="obCategories"
              description="Margin value increase through boost of Own Brand sales"
              subheader="Up to 31/12/2020, Monthly Average"
            />
          </div>
        </b-col>
      </b-form-row>
    </b-container>
  </div>
</template>

<script>
import Gauge from "@/components/Plots/Gauge.vue";
import BarChart from "@/components/Plots/BarChart.vue";
import HorizontalBarChart from "@/components/Plots/HorizontalBarChart.vue";
import AreaChart from "@/components/Plots/AreaChart.vue";

export default {
  name: "global-view",
  components: {
    Gauge,
    BarChart,
    HorizontalBarChart,
    AreaChart
  },
  data() {
    return {
      locale: "de",
      //
      digitalCustomerCategories: [],
      cumulativeDigitalCustomers: [],
      //
      totalToolCategories: [],
      totalToolCustomers: [],
      //
      newToolCategories: [],
      newToolCustomers: [],
      //
      toolRevenueCategories: [],
      totalToolRevenue: [],
      //
      averageSalesCategories: [],
      averageSalesValue: [],
      //
      purchasingFrequency: [],
      purchasingCategories: [],
      //
      cumulativeValue: [],
      cumulativeValueCategories: [],
      //
      salesUpliftCategories: [],
      salesUplift: [],
      //
      churnCategories: [],
      churnValue: [],
      //
      obProportion: [],
      obCategories: [],
      //
      timer: ""
    };
  },
  methods: {
    fetchData() {
      this.fetchDigitalCustomers();
      this.fetchTotalToolCustomers();
      this.fetchNewToolCustomers();
      this.fetchToolRevenue();
      this.fetchSalesValue();
      this.fetchCumulativeValue();
      this.fetchSalesUplift();
      this.fetchChurn();
      this.fetchObProportion();
    },
    fetchDigitalCustomers() {
      this.$http
        .get(`/api/v1/customers/digital`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.digitalCustomerCategories = response.data.categories;
          this.cumulativeDigitalCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchTotalToolCustomers() {
      this.$http
        .get(`/api/v1/customers/total`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.totalToolCategories = response.data.categories;
          this.totalToolCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchNewToolCustomers() {
      this.$http
        .get(`/api/v1/customers/new`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.newToolCategories = response.data.categories;
          this.newToolCustomers = response.data.data;
        })
        .catch(() => {});
    },
    fetchToolRevenue() {
      this.$http
        .get(`/api/v1/tools/revenue`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.toolRevenueCategories = response.data.categories;
          this.totalToolRevenue = response.data.data;
        })
        .catch(() => {});
    },
    fetchSalesValue() {
      this.$http
        .get(`/api/v1/transactions/sales`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.averageSalesValue = response.data.basket.data;
          this.averageSalesCategories = response.data.basket.categories;

          this.purchasingFrequency = response.data.visits.data;
          this.purchasingCategories = response.data.visits.categories;
        })
        .catch(() => {});
    },
    fetchCumulativeValue() {
      this.$http
        .get(`/api/v1/transactions/cumulativevalue`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.cumulativeValue = response.data.data;
          this.cumulativeValueCategories = response.data.categories;
        })
        .catch(() => {});
    },
    fetchSalesUplift() {
      this.$http
        .get(`/api/v1/transactions/uplift`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.salesUplift = response.data.actual;
          this.salesUpliftCategories = response.data.categories;
        })
        .catch(() => {});
    },
    fetchChurn() {
      this.$http
        .get(`/api/v1/customers/churn`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          this.churnCategories = response.data.categories;
          this.churnValue = response.data.data;
        })
        .catch(() => {});
    },
    fetchObProportion() {
      this.$http
        .get(`/api/v1/transactions/ownbrand`, {
          params: {
            country: "global"
          }
        })
        .then(response => {
          // JSON responses are automatically parsed.
          this.obCategories = response.data.categories;
          this.obProportion = response.data.data;
        })
        .catch(() => {});
    },
    submitPageChange() {
      document.dispatchEvent(
        new CustomEvent("adobeDataLayerEvent", {
          detail: {
            event: "content_view_change",
            page: {
              pageName: "EN:XX:VALUE CREATION DASHBOARD: " + document.URL,
              productSection: "private",
              productType: "VALUE CREATION DASHBOARD",
              userLanguage: "EN",
              usercountry: "XX",
              userType: this.$vueRole,
              userID: this.$kcUserId
            }
          }
        })
      );
    }
  },
  created() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 7200000);
    this.submitPageChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
h2 {
  font-size: 37px;
  margin-bottom: 5px;
}
</style>
