<template>
  <div class="info_box">
    <div class="header"><slot></slot></div>
    <hr class="hr" />
    <div class="footer">
      {{ footer }}
      <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
      <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
        <div class="tooltip-internal">
          {{ description }}
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    footer: String,
    description: { type: String, default: "" }
  },
  computed: {
    hasDescription: function() {
      return this.description != "";
    }
  }
};
</script>

<style scoped>
.info_box {
  background-color: #002146;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

.header {
  margin-left: 5px;
}

.hr {
  margin-bottom: 0;
  margin-top: 0;
  border-color: rgb(230, 230, 230);
}

.footer {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-icon {
  font-size: 11px;
}
</style>
