<template>
  <b-sidebar
    id="sidebar-1"
    shadow
    no-header
    backdrop-variant="dark"
    backdrop
    right
  >
    <template #default="{ hide }">
      <div class="px-3 py-2">
        <div v-if="isAdmin == true" id="list-container">
          <ul class="sidebar-panel-nav">
            <li v-for="view in views" :key="view.name">
              <router-link v-on:click.native="hide" :to="view.path">
                <div
                  class="selection-container"
                  v-bind:class="isCurrentRoute(view.path)"
                >
                  {{ view.name }}
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div v-if="isUser == true" id="list-container">
          <ul class="sidebar-panel-nav">
            <li v-for="view in view2" :key="view.name">
              <router-link v-on:click.native="hide" :to="view.path">
                <div
                  class="selection-container"
                  v-bind:class="isCurrentRoute(view.path)"
                >
                  {{ view.name }}
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import Vue from "vue";

export default {
  name: "sidebar",
  data() {
    return {
      rbac: "",
      isAdmin: false,
      isUser: false,
      views: [
        { path: "/", name: "Home" },
        // { path: "/value", name: "Value Creation" },
        // { path: "/overview", name: "Global Overview" },
        { path: "/country", name: "Country Overview" }
      ],
      view2: [
        { path: "/", name: "Home" },
        { path: "/country", name: "Country Overview" }
      ]
    };
  },
  watch: {
    rbac: function(val) {
      this.setmenu(val);
    }
  },
  mounted() {
    this.setmenu(this.rbac);
  },
  created() {
    this.getrbac();
  },
  methods: {
    isCurrentRoute(value) {
      if (this.$route.path === value) {
        return "current_route";
      }
    },
    setmenu(rbac) {
      if (!rbac) {
        this.isUser = true;
      } else if (rbac.includes("country_all")) {
        this.isAdmin = true;
      } else {
        this.isUser = true;
      }
    },
    getrbac() {
      if (Vue.$roles.length != 0) {
        this.rbac = Vue.$roles;
      } else {
        this.rbac = Vue.$keycloak.tokenParsed.realm_access.roles;
      }
    }
  }
};
</script>

<style scoped>
#list-container {
  margin-top: 10px;
}

ul.sidebar-panel-nav {
  list-style-type: none;
  padding-inline-start: 15px;
}
ul.sidebar-panel-nav > li > a {
  text-decoration: none;
  display: block;
}
.selection-container {
  color: #909090;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 15px 10px;
  border-bottom: 1px solid #909090;
}
.selection-container:hover {
  color: rgb(51 51 51);
}
.current_route {
  color: black;
  margin-top: -1px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

hr {
  background-color: #828282;
  margin: 0;
}
</style>
