<template>
  <div>
    <Template country="Slovakia" locale="sk" />
  </div>
</template>

<script>
import Template from "@/views/CountryViews/Template.vue";

export default {
  name: "slovakia-view",
  components: {
    Template
  }
};
</script>
