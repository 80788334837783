<template>
  <div v-if="isLoading" class="plot-container text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <div class="h2 mb-0">
      <div class="plot-title">
        {{ title }}
        <b-icon v-if="hasDescription" icon="info-circle" ref="info"></b-icon>
        <b-tooltip v-if="hasDescription" :target="() => $refs['info']">
          <div class="tooltip-internal">
            {{ description }}
          </div>
        </b-tooltip>
      </div>
      <div class="plot-subheader">{{ subheader }}</div>
    </div>
    <apexchart
      type="bar"
      :height="plotHeight"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import formatValue from "@/components/Plots/formatter";

export default {
  name: "ApexColumnWithDataLabels",
  props: {
    title: String,
    series: Array,
    categories: Array,
    height: { type: Number, default: 220 },
    description: { type: String, default: "" },
    subheader: { type: String, default: "" },
    stacked: { type: Boolean, default: false },
    isCurrency: { type: Boolean, default: false },
    isPercentage: { type: Boolean, default: false },
    locale: { type: String },
    enableDataLabels: { type: Boolean, default: true },
    shortAxisLabels: { type: Boolean, default: false }
  },
  computed: {
    plotHeight: function() {
      if (this.subheader === "") {
        return this.height + 12;
      } else {
        return this.height;
      }
    },
    isLoading: function() {
      return this.series.length === 0;
    },
    hasDescription: function() {
      return this.description != "";
    },
    chartOptions: function() {
      return {
        colors: ["rgb(249, 174, 0)", "rgb(0, 59, 126)", "rgb(112, 174, 223)"],
        title: {
          text: ""
        },
        chart: {
          fontFamily: "Calibri",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            easing: "easein",
            speed: 500,
            animateGradually: {
              delay: 50
            }
          },
          stacked: this.stacked
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        stroke: {
          show: true,
          width: 0.5,
          colors: ["black"]
        },
        dataLabels: {
          enabled: this.enableDataLabels && !this.stacked,
          offsetY: -20,
          style: {
            fontSize: "9px",
            colors: ["black"]
          },
          formatter: value => {
            return formatValue(
              value,
              this.locale,
              this.isCurrency,
              this.isPercentage,
              true
            );
          }
        },
        xaxis: {
          categories: this.categories,
          position: "top",
          labels: {
            style: {
              colors: "black"
            },
            formatter: value => {
              if (this.shortAxisLabels) {
                return value.substring(0, 3);
              } else {
                return value;
              }
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.5,
                opacityTo: 0.7
              }
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: !this.enableDataLabels || this.stacked,
            style: {
              colors: "black",
              fontWeight: 900
            },
            offsetX: -10,
            formatter: value => {
              return formatValue(
                value,
                this.locale,
                this.isCurrency,
                this.isPercentage,
                true
              );
            }
          }
        },
        tooltip: {
          x: {
            formatter: function(value) {
              return value;
            }
          },
          y: {
            formatter: value => {
              return formatValue(
                value,
                this.locale,
                this.isCurrency,
                this.isPercentage,
                false
              );
            }
          }
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
            top: -20,
            bottom: this.plotHeight < 150 ? -25 : -10
          }
        }
      };
    }
  }
};
</script>
